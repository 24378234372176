import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import API_ADD from "../config.js";

function Playmovie() {
  const [data, setData] = useState([]); // 動画情報取得APIの変数宣言
  const [tagdata, setTagData] = useState([]); // getSearchListAPIの変数宣言
  const { id } = useParams();
  const idNumber = parseInt(id, 10); // URLパラメーターの取得及び型の変換
  const [firstPlay, setFirstPlay] = useState(true); // APIコールの状態を管理

  const theme = responsiveFontSizes(createTheme()); // 動画タイトルレスポンシブ対応用
  let navigate = useNavigate();

  // countVideoAPIのコール関数
  const countVideo = async () => {
    if (firstPlay) {
      const requestData = {
        id: idNumber,
        register_date: data.method?.register_date,
      };
      try {
        const response = await axios.post(API_ADD.COUNT_VIDEO, requestData);
        console.log(response.data);
        // APIのレスポンスを処理する部分
      } catch (error) {
        // エラーハンドリング
        console.error("Error:", error);
      }
      setFirstPlay(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(API_ADD.PLAY_MOVIE_ID(id));
        setData(response.data);

        if (response.data.method && response.data.method.tag) {
          const tagString = response.data.method.tag.join(",");
          const tagResponse = await axios.get(API_ADD.GET_SEARCH_LIST, {
            params: { tag: tagString },
          });
          setTagData(tagResponse.data.data);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [id]); // id を依存関係に含める
  const searchTagData = tagdata[0];

  const baseConfig = {
    url: data.method?.video_url,
    controls: true,
    width: "100%",
    height: "100%",
    onPlay: countVideo,
    config: {
      file: {
        attributes: {
          controlsList: "nodownload", // ダウンロードボタンを無効にする
        },
      },
    },
  };

  if (data.method?.setting_thumbnail_url) {
    baseConfig.config.file.attributes.poster =
      data.method.setting_thumbnail_url;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Box
            sx={{
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ReactPlayer {...baseConfig} />
          </Box>
          <Box
            sx={{
              maxWidth: "700px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center", // 中央配置
              padding: "15px",
              paddingLeft: "0px",
            }}
          >
            <ThemeProvider theme={theme}>
              <Typography variant="h6">{data.method?.title}</Typography>
            </ThemeProvider>
          </Box>
          <Box
            sx={{
              maxWidth: "700px",
              flexDirection: "column",
              justifyContent: "center", // 中央配置
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ color: "#757575", fontSize: "14px", fontWeight: "bold" }}
            >
              更新日：{data.method?.update_date.split(" ", 1)}　再生数：
              {data.method?.views}　タグ：
              <Link
                to={"/tagvideolist"}
                state={{ tagName: data.method?.tag }}
                target="_self"
                rel="noopener noreferrer"
              >
                {data.method?.tag}
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              maxWidth: "700px",
              flexDirection: "column",
              justifyContent: "center", // 中央配置
              padding: "8px",
              bgcolor: "grey.200",
              borderRadius: "8px",
            }}
          >
            <Typography
              variant="body1"
              sx={{ fontSize: "16px", whiteSpace: "pre-line" }}
            >
              {data.method?.description}
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start", // 上に寄せる
            alignItems: "center",
            "@media (max-width: 1100px)": {
              display: "none", // 横幅が1100未満の場合、非表示にする
            },
          }}
        >
          {searchTagData?.video_list.map((tagItem, index) =>
            id !== tagItem.id ? (
              <Card
                sx={{ maxWidth: 270, marginBottom: 2 }}
                key={index}
                onClick={() => navigate(`/playmovie/${tagItem.id}`)}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    style={{ width: 270, height: "auto" }}
                    image={
                      tagItem.setting_thumbnail_url
                        ? tagItem.setting_thumbnail_url
                        : tagItem.thumbnail_url
                    }
                    alt="green iguana"
                  />
                  <CardContent
                    sx={{
                      height: 70,
                      padding: "3px",
                      paddingLeft: "7px",
                      paddingRight: "7px",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="subtitle2"
                      component="div"
                      sx={{
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: "3",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {tagItem.title}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ height: 30, padding: "3px", paddingLeft: "7px" }}
                  >
                    <Typography variant="body2" color="text.secondary">
                      <span>更新日: {tagItem.update_date.split("T", 1)}</span>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <span>再生数: {tagItem.views}</span>
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            ) : null,
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default Playmovie;
