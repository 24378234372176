import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";

import "./../App.css";

// ドコパヘッダーコンポーネント
import DocopaHeader from "./../components/DocopaHeader.js";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Box className="centered" style={{ height: "80vh" }}>
      {
        <DocopaHeader
          isManagePath={false}
          cursor={false}
          handleLogoClick={null}
          signOutBtn={false}
        />
      }
      <Typography variant="h4">ページが見つかりません。</Typography>
      <br />
      <Typography variant="h5">
        申し訳ありません。アクセスされたページは見つかりませんでした。
      </Typography>
      <Button
        onClick={() => navigate(`/top`)}
        variant="contained"
        sx={{ mx: "auto", my: "30px" }}
      >
        Topへ戻る
      </Button>
    </Box>
  );
}

export default NotFound;
