/**
 * 管理者メイン画面
 */
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import API_ADD from "../config.js";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";

// ドコパヘッダーコンポーネント
import DocopaHeader from "../components/DocopaHeader.js";
import CustomModal from "../components/Modal.js";
import { fetchUserGroups } from "../variables/ManageRelated.js";
import NotFound from "./NotFound.js";

function Manage() {
  const navigate = useNavigate();
  const [data, setData] = useState([]); //動画情報取得APIの変数宣言
  const [showModal, setShowModal] = useState(false); //モーダル用状態
  // ログインユーザーの権限確認
  const [userGroups, setUserGroups] = useState([]);
  // ログインユーザー確認
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    // 動画情報の取得APIコール
    const fetchData = async () => {
      try {
        const response = await axios.get(API_ADD.GET_VIDEO_LIST);
        setData(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    // ユーザーのグループ取得
    fetchUserGroups(setUserGroups, setUserInfo);
    // eslint-disable-next-line
  }, []); // 空の依存リストを指定して、コンポーネントがマウントされたときにのみAPIコールを行う

  const tagsTitleMap = new Map(); // タグとタイトルの対応を管理する Map

  if (data.data) {
    data.data.forEach((item) => {
      item.tag.forEach((tag) => {
        if (!tagsTitleMap.has(tag)) {
          tagsTitleMap.set(tag, []);
        }
        tagsTitleMap.get(tag).push({
          id: item.id,
          title: item.title,
          thumbnailUrl: item.setting_thumbnail_url || item.thumbnail_url,
          status: item.status,
        });
      });
    });
  }

  const uniqueTags = Array.from(tagsTitleMap.keys());

  // 削除ボタンがクリックされたときの処理
  async function handleDelete(videoId) {
    try {
      setShowModal(true);

      const headers = {
        "Content-Type": "application/x-www-form-urlencoded",
      };

      // POSTリクエストのリクエストボディデータを設定
      const numericVideoId = parseInt(videoId, 10);

      const postData = {
        videoId: numericVideoId,
      };

      // Axiosを使用してPOSTリクエストを送信
      const postEndpoint = API_ADD.DELETE_VIDEO_ID; // DELETEエンドポイントをPOSTエンドポイントに変更
      const response = await axios.post(postEndpoint, postData, {
        headers: headers,
      }); // POSTリクエストに変更
      const data = response.data;

      // レスポンスを処理する
      if (data.resultCode === 2000) {
        // 削除成功の場合の処理
        alert("削除が成功しました");

        // ここで動画一覧の再読み込みなどの処理を行う
        window.location.reload();
      } else {
        // 削除失敗の場合の処理
        alert("削除が失敗しました");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // モーダルを非表示
      setShowModal(false);
    }
  }
  // 管理者権限ない場合、NotFrundへ遷移
  if (userGroups === undefined) return <NotFound />;
  return (
    <>
      <DocopaHeader
        isManagePath={true}
        cursor={true}
        handleLogoClick={() => navigate(`/manage`)}
        signOutBtn={userInfo && true}
      />
      <Container style={{ textAlign: "center" }}>
        <Typography variant="h2" gutterBottom style={{ marginTop: "20px" }}>
          運営者画面
        </Typography>
        <Button
          variant="contained"
          style={{
            width: "130px",
            height: "63px",
            fontSize: "20px",
            padding: "12px 12px",
          }}
          onClick={() => {
            navigate("/manage/register");
          }}
        >
          動画登録
        </Button>
        <Divider
          style={{
            margin: "20px 0",
            borderStyle: "dashed",
            borderWidth: "3px",
          }}
        />
        {uniqueTags.map((tag, index) => (
          <div key={index} style={{ marginBottom: "50px" }}>
            <Typography variant="h4" style={{ textAlign: "left" }}>
              {tag}
            </Typography>
            {tagsTitleMap.get(tag).map(
              (videoInfo, titleIndex) =>
                videoInfo.status === "COMPLETE" && (
                  <Box key={titleIndex} sx={{ flexGrow: 1 }}>
                    <div style={{ marginTop: "20px" }}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={4}>
                          <div
                            key={titleIndex}
                            style={{
                              display: "flex",
                            }}
                          >
                            <CardMedia
                              component="img"
                              style={{ width: "100%", height: "auto" }}
                              image={videoInfo.thumbnailUrl}
                              alt="Thumbnail"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography
                            variant="h5"
                            style={{
                              whiteSpace: "normal",
                              overflow: "hidden",
                              display: "-webkit-box",
                              WebkitBoxOrient: "vertical",
                              WebkitLineClamp: 5, // 2行まで表示
                            }}
                          >
                            {videoInfo.title}
                          </Typography>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="outlined"
                            style={{
                              width: "130px",
                              height: "63px",
                              fontSize: "20px",
                              padding: "12px 24px",
                              borderWidth: "2px",
                            }}
                            onClick={() => {
                              navigate(`/manage/edit`, {
                                state: { id: videoInfo.id },
                              });
                            }}
                          >
                            編集
                          </Button>
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="outlined"
                            color="error"
                            style={{
                              width: "130px",
                              height: "63px",
                              fontSize: "20px",
                              padding: "12px 24px",

                              borderWidth: "2px",
                            }}
                            onClick={() => {
                              const confirmed = window.confirm(
                                `${videoInfo.title}を削除してもよろしいですか？`
                              );
                              if (confirmed) {
                                handleDelete(videoInfo.id);
                              }
                            }}
                          >
                            削除
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                )
            )}
          </div>
        ))}
      </Container>
      <CustomModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
}

export default Manage;
