import { TextField, Container, Box, Typography, Button } from "@mui/material";
import { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
// ドコパヘッダーコンポーネント
import DocopaHeader from "../components/DocopaHeader.js";
import { checkReq, explanText } from "../variables/ManageRelated.js";
import { useForm } from "react-hook-form";
import DeleteIcon from "@mui/icons-material/Delete";

function CommonForm({ onSubmit, initVal, state, setThumbnailDel, signOutBtn }) {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const videoFileMax = 1000 * 1024 * 1024; // 1000MB;
  const descriptionMax = 2000;
  // 動画ファイルプレビュー
  const [videoUrl, setvideoUrl] = useState(null);
  // 更新の動画ファイルプレビュー
  const [videoImgUrl, setvideoImgUrl] = useState(null);
  const [videoText, setVideoText] = useState(null);
  // 画像ファイルプレビュー
  const [imageUrl, setImageUrl] = useState(null);
  const [imageText, setImageText] = useState(null);
  // 更新画面: 既存サムネイルある場合 true : 削除ボタン表示
  const [imageDelBtn, setImageDelBtn] = useState(false);
  // 更新画面: 削除ボタンクリック/ サムネイルへ別ファイル選択し true
  const [imageDelFlg, setImageDelFlg] = useState(false);
  const [submitText, setSubmitText] = useState(null);

  // 登録・更新の動画・サムネイルのヘルパーテキスト/submitボタンの文言設定
  useEffect(() => {
    setVideoText(
      state === "edit"
        ? explanText.video.EditHelperText
        : explanText.video.helperText
    );
    setImageText(
      state === "edit"
        ? explanText.thumbnail.EditHelperText
        : explanText.thumbnail.helperText
    );
    setSubmitText(state === "edit" ? "更新" : "登録");
    setImageDelBtn(state === "edit" && !!initVal.setting_thumbnail_url);
    // eslint-disable-next-line
  }, []);

  // 動画ファイル検知
  const videoWatch = watch("video");
  useEffect(() => {
    clearErrors("video");
    if (videoWatch && videoWatch.length > 0) {
      setvideoUrl(URL.createObjectURL(videoWatch[0]));
      setvideoImgUrl(null);
      // 動画ファイルではない
      if (!videoWatch[0]?.type.startsWith("video/")) {
        setvideoUrl(null);
        setvideoImgUrl(null);
        setError("video", {
          message: explanText.video.otherFileErrText,
        });
      }
      // 動画サイズ確認
      if (videoWatch[0]?.size >= videoFileMax) {
        setvideoUrl(null);
        setvideoImgUrl(null);
        setError("video", {
          message: explanText.video.fileSizeErrText,
        });
      }
    } else {
      // キャンセルボタンクリック処理
      setvideoUrl(state === "edit" ? initVal.video_url : null);
      setvideoImgUrl(state === "edit" ? initVal.thumbnail_url : null);
    }
    // eslint-disable-next-line
  }, [videoWatch, clearErrors, setError]);

  // サムネイルファイル検知
  const thumbnailWatch = watch("thumbnail");
  useEffect(() => {
    clearErrors("thumbnail");

    if (thumbnailWatch && thumbnailWatch.length > 0) {
      setImageDelBtn(false); // 別ファイルを選択すると、削除ボタンなくす
      setImageDelFlg(true);
      setImageUrl(URL.createObjectURL(thumbnailWatch[0]));
      // 画像ファイルではない
      if (!thumbnailWatch[0]?.type.startsWith("image/")) {
        setImageUrl(null);
        setError("thumbnail", {
          message: explanText.thumbnail.errText,
        });
      }
    } else {
      // キャンセルボタンクリック処理
      setImageUrl(
        state === "edit" && !imageDelFlg ? initVal.setting_thumbnail_url : null
      );
    }
    // eslint-disable-next-line
  }, [thumbnailWatch, clearErrors, setError]);

  // 動画概要検知
  const descriptionWatch = watch("descriptions");
  useEffect(() => {
    clearErrors("descriptions");
    if (descriptionWatch && descriptionWatch.length > descriptionMax) {
      setError("descriptions");
    }
  }, [descriptionWatch, clearErrors, setError]);

  return (
    <>
      <DocopaHeader
        isManagePath={true}
        cursor={true}
        handleLogoClick={() => navigate(`/manage`)}
        signOutBtn={signOutBtn}
      />
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "80%" },
            }}
          >
            <TitleLine
              title="タイトル"
              color={checkReq.required.color}
              text={checkReq.required.text}
            />
            <TextField
              name="title"
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={
                errors.title
                  ? errors.title.message
                  : explanText.title.helperText
              }
              error={!!errors.title}
              {...register("title", {
                required: explanText.title.errText,
              })}
              defaultValue={initVal.title}
            />
          </Box>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "80%" },
            }}
          >
            <TitleLine
              title="タグ"
              color={checkReq.required.color}
              text={checkReq.required.text}
            />
            <TextField
              name="tag"
              fullWidth
              margin="normal"
              variant="outlined"
              helperText={
                errors.tag ? errors.tag.message : explanText.tag.helperText
              }
              error={!!errors.tag}
              {...register("tag", {
                required: explanText.tag.errText,
              })}
              defaultValue={initVal.tag[0]}
            />
          </Box>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "80%" },
            }}
          >
            <TitleLine
              title="動画概要"
              color={checkReq.elective.color}
              text={checkReq.elective.text}
            />
            <TextField
              name="descriptions"
              id="outlined-multiline-static"
              multiline
              rows={4}
              helperText={`動画の説明文を入力してください。（${
                descriptionWatch ? descriptionWatch.length : 0
              } / ${descriptionMax}文字）`}
              error={!!errors.descriptions}
              {...register("descriptions", {
                maxLength: descriptionMax,
              })}
              defaultValue={initVal.description}
            />
          </Box>
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "80%" },
            }}
          >
            <TitleLine
              title="動画ファイル"
              color={checkReq.required.color}
              text={checkReq.required.text}
            />
            <div style={{ width: 550, float: "left" }}>
              <TextField
                name="video"
                fullWidth
                margin="normal"
                variant="outlined"
                type="file"
                inputProps={{
                  accept: "video/*",
                }}
                helperText={errors.video ? errors.video.message : videoText}
                error={!!errors.video}
                {...register("video", {
                  required:
                    state === "edit" ? false : explanText.video.nonErrText,
                  validate: {
                    acceptedFormats: (files) => {
                      if (files[0] && !files[0]?.type.startsWith("video/")) {
                        return explanText.video.otherFileErrText;
                      }
                    },
                    moreThan500MB: (files) => {
                      if (files[0] && files[0]?.size >= videoFileMax) {
                        return explanText.video.fileSizeErrText;
                      }
                    },
                  },
                })}
              />
            </div>
            {!videoUrl ? (
              <NonFileBox />
            ) : (
              <video
                src={videoUrl}
                alt="アップロード画像"
                poster={videoImgUrl}
                style={{
                  width: "350px",
                  minHeight: "200px",
                  marginTop: "0.5rem",
                }}
              />
            )}
          </Box>
          <div style={{ clear: "both" }} />
          <Box
            sx={{
              "& .MuiTextField-root": { m: 1, width: "80%" },
            }}
          >
            <TitleLine
              title="サムネイル"
              color={checkReq.elective.color}
              text={checkReq.elective.text}
            />
            <div style={{ dispay: "flex", alignItems: "center" }}>
              <div style={{ width: 550, float: "left" }}>
                <TextField
                  name="thumbnail"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  type="file"
                  inputProps={{
                    accept: "image/*",
                  }}
                  helperText={
                    errors.thumbnail ? errors.thumbnail.message : imageText
                  }
                  error={!!errors.thumbnail}
                  {...register("thumbnail", {
                    validate: {
                      acceptedFormats: (files) => {
                        if (files[0] && !files[0]?.type.startsWith("image/")) {
                          return explanText.thumbnail.errText;
                        }
                      },
                    },
                  })}
                />
                {imageDelBtn && (
                  <Button
                    variant="contained"
                    sx={{ marginLeft: "10px" }}
                    onClick={() => {
                      setImageUrl(null);
                      setImageDelBtn(false);
                      setImageDelFlg(true);
                      setThumbnailDel(true);
                    }}
                  >
                    <DeleteIcon />
                    表紙画像の削除
                  </Button>
                )}
              </div>
              {!imageUrl ? (
                <NonFileBox />
              ) : (
                <img
                  src={imageUrl}
                  alt="アップロード画像"
                  style={{
                    width: "350px",
                    minHeight: "200px",
                    marginTop: "0.5rem",
                  }}
                />
              )}
            </div>
          </Box>
          <div style={{ clear: "both" }} />
          <Button
            variant="contained"
            sx={{ width: "100px", mx: "auto", my: "30px" }}
            type="submit"
          >
            {submitText}
          </Button>
          <Button
            variant="outlined"
            sx={{ width: "100px", mx: "auto", my: "30px", marginLeft: "5px" }}
            onClick={() => navigate(`/manage`)}
          >
            戻る
          </Button>
        </form>
      </Container>
    </>
  );
}
/**
 * @param title　ラベルテキスト
 * @param color　必須・任意のbgカラー
 * @param text 必須・任意
 * @returns 入力ラベル・必須・任意バッチ
 */
const TitleLine = ({ title, color, text }) => {
  return (
    <>
      <Typography
        sx={{
          width: "10%",
          float: "left",
          marginTop: "1.75rem",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Typography
        sx={{
          width: "4%",
          float: "left",
          marginTop: "1.9rem",
          backgroundColor: color,
          color: "white",
          borderRadius: "5px",
          textAlign: "center",
          fontSize: "12px",
        }}
      >
        {text}
      </Typography>
    </>
  );
};

/** ビデオ・サムネイル未選択の場合表示 */
const NonFileBox = () => {
  return (
    <div
      style={{
        width: "350px",
        minHeight: "200px",
        float: "left",
        borderRadius: "5px",
        background: "#EEEEEE",
        marginTop: "0.5rem",
      }}
    >
      <p style={{ textAlign: "center" }}>プレビュー</p>
    </div>
  );
};

export default CommonForm;
