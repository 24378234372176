/**
 * 編集画面
 */
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import { checkFileName, fetchUserGroups } from "../variables/ManageRelated.js";
import NotFound from "./NotFound.js";
import CommonForm from "../components/CommonForm.js";
import API_ADD from "../config.js";
import ProgressBarDialog from "../components/ProgressBarDialog.js";

function Edit() {
  const location = useLocation();
  const [id, setId] = useState();

  const [data, setData] = useState();
  const [loding, setLoding] = useState(true);
  // ログインユーザーの権限確認
  const [userGroups, setUserGroups] = useState([]);
  // ログインユーザー確認
  const [userInfo, setUserInfo] = useState();

  const [thumbnailDel, setThumbnailDel] = useState(false);
  // プログレスバーの表示関数
  const [open, setOpen] = useState(false);
  // パラメータない対応（URL直の接続）
  const [notFound, setNotFound] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [videoId, setVideoId] = useState("");
  const videoIdSet = (vid) => {
    setVideoId(vid);
  };

  // 画面初期表示のため、APIコール
  useEffect(() => {
    if (!location.state) {
      setNotFound(true);
      return;
    }
    setId(location.state.id);
    async function fetchData() {
      try {
        const res = await axios.get(API_ADD.PLAY_MOVIE_ID(location.state.id));
        setData(res.data.method);
        setLoding(false);
      } catch (e) {
        console.log(`error : ${e}`);
        setLoding(false);
      }
    }
    if (location.state) fetchData();
    // ユーザーのグループ取得
    fetchUserGroups(setUserGroups, setUserInfo);
    // eslint-disable-next-line
  }, []);

  // 日付のフォーマットYYYY-MM-DD hh:mm:ss
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  /** 更新ボタンクリック処理*/
  const onSubmit = async (inputData) => {
    // プログレスバーの表示
    handleClickOpen();

    // 更新日のため、現在時間取得
    const now_date = new Date();
    // DBに更新内容
    const editData = {
      count: id, // id
      registerDate: data.register_date, //　登録日
      videoTitle: inputData.title, //　タイトル
      videoTag: inputData.tag, //　タグ
      description: inputData.descriptions, //　動画概要
      update_date: formatDate(now_date), //　更新日（現在時間）
    };
    // 登録されてある既存サムネイルファイル情報格納
    const dbSettingThumbnailURL =
      data.setting_thumbnail_url || encodeURIComponent(null);
    const issue_headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    // 動画ファイル更新がある場合
    if (inputData.video.length > 0) {
      const issue_video_url_data = {
        param_key: `upload/input/movie/${id}_${checkFileName(
          inputData.video[0].name
        )}`,
      };

      await axios
        .post(API_ADD.ISSUE_URL, issue_video_url_data, {
          headers: issue_headers,
        })
        .then((res) => {
          if (res.data.resultCode === 2000) {
            editData.videoName = checkFileName(inputData.video[0].name);
            // 以下追加必要
            editData.defaultThumbnailURL = dbSettingThumbnailURL;
            // サムネイルファイル更新がある場合の処理
            if (inputData.thumbnail.length > 0) {
              editData.imageUrl = URL.createObjectURL(inputData.thumbnail[0]);
              editData.thumbnail = checkFileName(inputData.thumbnail[0].name);
            }
            // サムネイル削除された場合の処理
            else if (thumbnailDel) {
              editData.imageUrl = editData.thumbnail = encodeURIComponent(null);
            }
            // サムネイル更新がない場合の処理
            else {
              editData.imageUrl =
                data.setting_thumbnail_url || encodeURIComponent(null);
              editData.thumbnail = encodeURIComponent(null);
            }

            const s3_path = "upload/output/movie";
            const path = require("path-browserify");
            const delete_objects = {
              video: path.join(s3_path, data.video_url.split("/").slice(-1)[0]),
              thumbnail: path.join(
                s3_path,
                data.thumbnail_url.split("/").slice(-1)[0]
              ),
            };
            editData.delete_s3_keys = JSON.stringify(delete_objects);

            file_upload(
              res.data.presignedUrl,
              inputData.video[0],
              editData,
              inputData
            );
          } else if (res.data.resultCode === 5000) {
            alert("署名付きURLの取得に失敗しました");
          }
        })
        .catch((error) => {
          console.error(`failed connect, ${error}`);
          alert("サーバとの通信に失敗しました");
        });
      // (動画と)サムネイルの更新がある時はファイルをアップロードする
      // サムネイルをDBと動画の前にアップロードすると
      // DBステータス設定の前に処理が終わる可能性があるため、ここで記載
      if (
        !(
          inputData.thumbnail.length === 0 ||
          inputData.thumbnail === dbSettingThumbnailURL
        )
      ) {
        const issue_thumbnail_url_data = {
          param_key: `upload/input/image/${id}_${checkFileName(
            inputData.thumbnail[0].name
          )}`,
        };

        await axios
          .post(API_ADD.ISSUE_URL, issue_thumbnail_url_data, {
            headers: issue_headers,
          })
          .then((res) => {
            if (res.status === 200) {
              // サムネイルファイル更新がある場合、サムネイル更新
              thumbnail_upload(inputData.thumbnail[0], res.data.presignedUrl);
            } else if (res.data.resultCode === 5000) {
              alert("署名付きURLの取得に失敗しました");
            }
          })
          .catch((error) => {
            console.error(`failed connect, ${error}`);
            alert("サーバとの通信に失敗しました");
          });
      }
    } else {
      // 動画ファイルがなかった時の処理
      if (inputData.thumbnail.length > 0) {
        // サムネイルファイル更新がある場合、リクエスト情報へ追加
        editData.thumbnail_name = checkFileName(inputData.thumbnail[0].name);
        edit_Database(editData, inputData, true);
      } else {
        if (thumbnailDel) {
          // サムネイル削除された場合の処理
          editData.imageUrl = encodeURIComponent(null);
        } else {
          editData.imageUrl =
            data.setting_thumbnail_url || encodeURIComponent(null);
        }
        editData.defaultThumbnailURL = dbSettingThumbnailURL;
        edit_Database(editData, inputData);
      }
    }
  };

  /** DB 更新
   * @param editData 更新内容
   * @param inputData　FEの入力内容
   * @param flg　サムネイル更新処理のみtrue
   */
  function edit_Database(editData, inputData, flg = false) {
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };

    // DB更新APIコール
    axios
      .post(API_ADD.EDIT_VIDEO, editData, { headers: headers })
      .then((res) => {
        if (
          flg &&
          inputData.thumbnail.length > 0 &&
          res.data.resultCode === 2000
        ) {
          // サムネイルファイル更新がある場合、サムネイル更新
          thumbnail_upload(inputData.thumbnail[0], res.data.presignedUrl);
        }
        if (res.data.resultCode === 5001) {
          alert("動画情報の更新に失敗しました");
        }
      })
      .catch((error) => {
        console.error(`failed connect, error=${error}`);
        alert("サーバとの通信に失敗しました");
      });
    videoIdSet(id);
  }
  /** 動画ファイルアップロード＆DB更新
   * @param url issueUrlのpresignedUrl
   * @param file 動画ファイル
   * @param editData 更新内容
   * @param inputData　画面で入力された値
   */
  function file_upload(url, file, editData, inputData, delete_params = "None") {
    // 署名付きURLへHTTPリクエスト(PUT)を送信
    const videoHeaders = {
      "Content-Type": "upload/input/movie/*",
    };
    // S3更新
    axios
      .put(url, inputData.video[0], {
        headers: videoHeaders,
      })
      .then((res) => {
        if (res.status === 200) {
          edit_Database(editData, inputData);
        }
      })
      .catch((error) => {
        // 動画のアップロードでエラー
        console.log("動画のアップロードに失敗しました");
        console.log("err:", error);
      });
  }

  function thumbnail_upload(thumbnail_data, url) {
    console.log("サムネイルアップロード");
    const thumbnailHeaders = {
      "Content-Type": "upload/input/image/*",
    };
    axios
      .put(url, thumbnail_data, {
        headers: thumbnailHeaders,
      })
      .then((res) => {
        if (res.status === 200) {
          console.log("サムネイル画像のアップロードを完了しました");
        }
      })
      .catch((error) => {
        console.log(
          `サムネイル画像のアップロードに失敗しました, error=${error}`
        );
      });
  }

  // 管理者権限ない場合、NotFrundへ遷移
  if (userGroups === undefined) return <NotFound />;
  // idのパラメータが場合
  if (notFound) return <NotFound />;
  if (loding) return <div></div>;
  return (
    <>
      <CommonForm
        onSubmit={onSubmit}
        initVal={data}
        state="edit"
        setThumbnailDel={setThumbnailDel}
        signOutBtn={userInfo}
      />
      <ProgressBarDialog
        open={open}
        handleClose={handleClose}
        videoId={videoId}
      />
    </>
  );
}
export default Edit;
