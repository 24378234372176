const vocabularies = {
  ja: {
    "Enter your Username": "ユーザー名を入力",
    "Enter your Password": "パスワードを入力",
    "Enter your Email": "Eメールアドレスを入力",
    "Confirm Password": "パスワード（確認用）",
    "Please confirm your Password": "パスワードを入力",
    "Code *": "MFAコード",
    "Confirm TOTP Code": "MFAコード確認",

    // ユーザー登録エラー内容
    "Username cannot be empty": "ユーザー名は必須です",
    "Password cannot be empty": "パスワードは必須です",
    "Your passwords must match": "パスワード（確認用）が一致しません",
    "User already exists": "ユーザーは既に存在します",
    "Password did not conform with policy: Password not long enough":
      "パスワードは8文字以上を入力してください (8文字以上の大文字小文字特殊文字を含む英数字)", // PW：長さ
    "Password did not conform with policy: Password must have lowercase characters":
      "パスワードには小文字を含めてください (8文字以上の大文字小文字特殊文字を含む英数字)", // PW：小文字
    "Password did not conform with policy: Password must have uppercase characters":
      "パスワードには大文字を含めてください (8文字以上の大文字小文字特殊文字を含む英数字)", // PW：大文字
    "Password did not conform with policy: Password must have symbol characters":
      "パスワードには特殊文字を含めてください (8文字以上の大文字小文字特殊文字を含む英数字)", // PW：特殊文字
    "Password did not conform with policy: Password must have numeric characters":
      "パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)", // PW：数字
    "PreSignUp failed with error email error.":
      "利用可能なメールアドレスのドメインではありません。(@nttdocomo.com/@s1.nttdocomo.com/@nttcom.co.jp/@yes.nttcom.ne.jp)",

    // ユーザー登録後の確認コード入力欄
    "We Texted You": "確認コードを送信しました。",
    "We Emailed You": "確認コードを送信しました。",

    "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
      "コードは準備中です。ログインするには、送信したコードを入力してください。到着までに 1 分ほどかかる場合があります。",

    // Cognito Server Side Error Messages
    // ref.) https://github.com/aws-amplify/amplify-js/issues/867
    "User does not exist.": "ユーザーが存在しません",
    "Incorrect username or password.": "ユーザー名またはパスワードが違います",
    "User is not confirmed.": "ユーザーは検証されていません",
    "Invalid verification code provided, please try again.":
      "指定された確認コードが無効です。もう一度お試しください",
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Account recovery requires verified contact information":
      "アカウントの復元には確認済みの連絡先情報が必要です",
    "An account with the given email already exists.":
      "そのメールアドレスは既に存在します",
    "Password attempts exceeded": "パスワード試行回数が超過しました",
    "Attempt limit exceeded, please try after some time.":
      "試行制限を超過しました。しばらくしてからもう一度お試しください",
    "Username/client id combination not found.": "ユーザーが存在しません",
    "CUSTOM_AUTH is not enabled for the client.": "パスワードは必須です", // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
    "Password does not conform to policy: Password not long enough":
      "パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)", // 適宜修正
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください", // 適宜修正。本来の意味とは異なるがこれで明示している。
  },
};

export default vocabularies;