import { useStateIfMounted } from "use-state-if-mounted";
import { useRef } from "react";
// Emotion
import { css } from "@emotion/css";
import PropTypes from "prop-types";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
  Typography,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import API_ADD from "../config.js";

export default function ProgressBarDialog(props) {
  const { handleClose, open, videoId } = props;
  const [progress, setProgress] = useStateIfMounted(0);
  const [checkState, setCheckState] = useStateIfMounted("");
  const [timeLimt, setTimeLimt] = useStateIfMounted(0);
  const [progressVal, setProgressVal] = useStateIfMounted("処理中");
  const [timeoutFlag, settimeoutFlag] = useStateIfMounted(false);
  const timer = useRef(null);
  const checkTimer = useRef(null);

  const progresscss = css`
    text-align: center;
    padding-top: 20px;
  `;

  const backcolorcss = css`
    color: #fff !important;
    background-color: #1d8cf8 !important;
  `;

  // 閉じるボタンの真ん中設定
  const closeBtnCss = css`
    margin: auto;
  `;

  // プログレスバーの進捗確認のため、APIコール
  const dbStatusCheck = (vid) => {
    if (vid) {
      axios
        .get(API_ADD.GET_REGIST_STATUS(vid))
        .then((res) => {
          if (res.data.resultCode === 2000) {
            if (res.data.status === "COMPLETE") {
              setCheckState("OK");
            }
          }
        })
        .catch((error) => {
          console.error("failed connect", error);
        });
    }
  };

  const progressUpdate = () => {
    if (timer.current == null) {
      timer.current = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 100 ? 0 : prevProgress + 5
        );
        setTimeLimt((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  // プログレスバーの停止
  const progressUpdateStop = () => {
    clearInterval(timer.current);
    timer.current = null;
  };

  // DBステータスの確認
  const checkStart = () => {
    if (checkTimer.current == null) {
      checkTimer.current = setInterval(() => {
        if (checkState !== "OK") {
          dbStatusCheck(videoId);
        }
        setTimeLimt((prevTime) => prevTime + 1);
      }, 1000);
    }
  };

  // DBステータスの確認停止
  const checkStop = () => {
    clearInterval(checkTimer.current);
    checkTimer.current = null;
  };

  // プログレスバーの表示時にプログレスバーの更新処理を開始
  if (props.open === true && progress === 0) {
    progressUpdate();
  }
  // Step：S3に動画がアップロードされる前
  if (progress === 25 && videoId === "" && checkState !== "OK") {
    progressUpdateStop();
  }

  // Step：S3に動画がアップロードされた場合、DBステータス確認を開始する
  if (
    progress === 25 &&
    videoId !== "" &&
    checkState !== "OK" &&
    timeoutFlag === false
  ) {
    checkStart();
    progressUpdate();
  }

  // Step：DBのステータスがCompleteではない場合
  if (progress === 95 && videoId !== "" && checkState !== "OK") {
    progressUpdateStop();
  }

  // Step：DBのステータスがCompleteになった
  if (
    progress !== 100 &&
    videoId !== "" &&
    checkState === "OK" &&
    timeoutFlag === false
  ) {
    // 更新時にDB更新が遅かった場合はProgress0でCOMPのままなので、再チェック
    if (progress <= 10) {
      setCheckState("");
      return;
    }
    setProgress((prevProgress) => 100);
    setProgressVal("完了");
  }

  // Step：動画登録処理がすべて完了
  if (progress === 100) {
    progressUpdateStop();
  }

  // 15分（900秒）時間制限
  if (timeLimt >= 900 && timeoutFlag === false) {
    progressUpdateStop();
    checkStop();
    setProgressVal("処理がタイムアウトしました");
    settimeoutFlag(true);
  }

  // DBステータスが確認済みの場合、確認処理を停止する。
  if (checkState === "OK") {
    checkStop();
  }

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      open={open}
      BackdropProps={{
        style: {
          backgroundColor: "#000",
          opacity: 0.3,
        },
      }}
    >
      <DialogContent sx={{ minWidth: "300px", minHeight: "200px" }}>
        <ProgressBarDialogWithLabel value={progress} />
        <DialogContentText className={progresscss}>
          {progressVal}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={closeBtnCss}>
        {progress === 100 ? (
          <Button
            variant="contained"
            size="large"
            onClick={handleClose}
            className={backcolorcss}
            to="/manage"
            component={Link}
          >
            閉じる
          </Button>
        ) : null}
        {timeoutFlag === true ? (
          <Button
            variant="contained"
            size="large"
            onClick={handleClose}
            className={backcolorcss}
            to="/manage"
            component={Link}
          >
            閉じる
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}

/** パーセント・ステータス表示*/
function ProgressBarDialogWithLabel(props) {
  const boxcss = css`
    left: 32%;
    top: 10px;
  `;

  const colorcss = css`
    color: #1d8cf8;
  `;

  return (
    <Box position="relative" display="inline-flex" className={boxcss}>
      <CircularProgress
        size={80}
        color={"inherit"}
        className={colorcss}
        variant="determinate"
        {...props}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="caption"
          component="div"
          color="textSecondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

ProgressBarDialogWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
