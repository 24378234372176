import { useState, useEffect } from "react";
import axios from "axios";

import { checkFileName, fetchUserGroups } from "../variables/ManageRelated.js";
import NotFound from "./NotFound.js";

import CommonForm from "../components/CommonForm.js";

import API_ADD from "../config.js";
import ProgressBarDialog from "../components/ProgressBarDialog.js";

function Register() {
  // プログレスバーの表示関数
  const [videoId, setVideoId] = useState();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // ログインユーザーの権限確認
  const [userGroups, setUserGroups] = useState([]);
  // ログインユーザー確認
  const [userInfo, setUserInfo] = useState();
  useEffect(() => {
    // ユーザーのグループ取得
    fetchUserGroups(setUserGroups, setUserInfo);
  }, []);

  const onSubmit = async (inputData) => {
    // Step1. DBに登録
    const data = {
      videoTitle: inputData.title,
      videoTag: inputData.tag,
      description: inputData.descriptions,
      videoFile: checkFileName(inputData.video[0].name),
    };

    if (inputData.thumbnail.length > 0) {
      data.thumbnailFile = checkFileName(inputData.thumbnail[0].name);
    }

    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    let videoUrl = "";
    let thumbnailUrl = "";

    // DB登録APIコール
    await axios
      .post(API_ADD.REGISTER_VIDEO, data, { headers: headers })
      .then((res) => {
        videoUrl = res.data.videoUrl;
        setVideoId(res.data.videoId);
        if (inputData.thumbnail.length > 0) {
          thumbnailUrl = res.data.thumbnailUrl;
        }
      })
      .catch((error) => {
        console.error("failed connect", error);
      });

    // Step2. プログレスバーの表示
    // videoIdが設定されないと表示できない
    handleClickOpen();

    // Step3. S3にアップロード 動画
    const videoHeaders = {
      "Content-Type": "upload/input/movie/*",
    };
    axios
      .put(videoUrl, inputData.video[0], { headers: videoHeaders })
      .then((res) => {
        if (res.status === 200) {
          console.log("動画のアップロードに成功しました");
        }
      })
      .catch((error) => {
        // 動画のアップロードでエラー
        console.log(`動画のアップロードに失敗しました, error=${error}`);
      });

    // Step4. S3にアップロード サムネイル
    if (inputData.thumbnail.length > 0) {
      const thumbnailHeaders = {
        "Content-Type": "upload/input/image/*",
      };
      axios
        .put(thumbnailUrl, inputData.thumbnail[0], {
          headers: thumbnailHeaders,
        })
        .then((res) => {
          if (res.status === 200) {
            console.log("サムネイル画像のアップロードを完了しました");
          }
        })
        .catch((error) => {
          console.log(
            `サムネイル画像のアップロードに失敗しました, error=${error}`
          );
        });
    }
  };

  // 初期値設定
  const initVal = {
    title: "",
    tag: [],
    description: [],
    setting_thumbnail_url: "",
    video_url: "",
  };
  // 管理者権限ない場合、NotFrundへ遷移
  if (userGroups === undefined) return <NotFound />;
  return (
    <>
      <CommonForm
        onSubmit={onSubmit}
        initVal={initVal}
        state="regist"
        signOutBtn={userInfo}
      />
      <ProgressBarDialog
        open={open}
        handleClose={handleClose}
        videoId={videoId}
      />
    </>
  );
}

export default Register;
