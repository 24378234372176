import axios from "axios";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

// ビデオカードリストコンポーネント
import VideoCardList from "../components/VideoCardList.js";
// BE API
import API_ADD from "../config.js";
import NotFound from "./NotFound.js";
// /docopa-dev-client/src/views/NotFound.js

/**
 * タグリスト画面
 */
function TagVideoList() {
  // getSearchListリクエスト
  const [data, setData] = useState([]);
  const [notFound, setNotFound] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // もっと見るをクリックしたタグデータ取得
    if (!location.state) {
      setNotFound(true);
      return;
    }
    // pramsからtags取得
    const tag = location.state.tagName[0];
    const fetchData = async () => {
      try {
        const res = await axios.get(API_ADD.GET_SEARCH_LIST, {
          params: { tag },
        });
        setData(res.data.data);
      } catch (e) {
        console.log(e);
      }
    };
    if (location.state) fetchData();
  }, [location.state]);

  // タグ名のパラメータが場合
  if (notFound) return <NotFound />;
  return (
    <>
      {
        //タグ単位の繰り返し処理
        data.map((data, i) => (
          <VideoCardList
            data={data}
            viewFlg={1}
            key={i}
            lastFlg={true}
            style={{ backgroundColor: "gray" }}
          />
        ))
      }
    </>
  );
}
export default TagVideoList;
