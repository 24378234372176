import axios from "axios";
import { useState, useEffect } from "react";

// ビデオカードリストコンポーネント
import VideoCardList from "../components/VideoCardList.js";

import { useLocation } from "react-router-dom";
import API_ADD from "../config.js";

/**
 * ドコモのトップ・検索結果
 */
function Top() {
  // getSearchListリクエスト
  const [data, setData] = useState([]);
  // getSearchListリクエスト長さ取得
  const [dataLength, setDataLength] = useState(0);
  // 画面フラグ top : 0 / tag : 1 / 検索 : 2
  const [viewFlg, setViewFlg] = useState(0);
  // Navigateで設定した引数を受け取るzw
  const location = useLocation();
  const state = location.state;

  useEffect(() => {
    // データ取得⇒検索フラグを同期的に行う
    const fetchData = async () => {
      await axios
        .get(API_ADD.GET_SEARCH_LIST, {
          params: { ...state },
        })
        .then((res) => {
          setData(res.data.data);
          setDataLength(res.data.data.length);
        })
        .catch((e) => console.log(e));

      // データ取得後にフラグを設定
      setViewFlg(state ? 2 : 0);
    };

    fetchData();
  }, [state]);

  return (
    <>
      {
        //タグ単位の繰り返し処理
        data.map((data, i) => (
          <VideoCardList
            data={data}
            viewFlg={viewFlg}
            key={i}
            lastFlg={dataLength === i + 1 ? true : false}
          />
        ))
      }
      {
        // 検索結果0件の場合
        data.length === 0 && viewFlg === 2 && (
          <div>
            <h2>検索結果がありません。</h2>
          </div>
        )
      }
    </>
  );
}
export default Top;
