import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function CustomModal({ open, onClose }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
    pointerEvents: open ? "auto" : "none",
  };
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropProps={{ style: { pointerEvents: "none" } }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          削除中...
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          削除が完了するまでお待ちください。
        </Typography>
      </Box>
    </Modal>
  );
}

export default CustomModal;
