import React from "react";
import { Auth } from "aws-amplify";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";

import headerDocopaLogo from "./../img/headerDocopaLogo.ico";

/**
 * ドコパヘッダーコンポーネント共通か
 * @param isManagePath 管理者URL：true
 * @param cursor カーソル表示（ログイン画面）：false
 * @param handleLogoClick ロゴのオンクリックイベント
 * @returns ドコパヘッダーコンポーネント
 */
function DocopaHeader({ isManagePath, cursor, handleLogoClick, signOutBtn }) {
  // ログアウト処理
  const handleLogout = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("Error signing out:", error);
    }
  };
  // カーソルの有効化：ログイン画面不可能
  const cursorStyle = cursor ? { cursor: "pointer" } : null;

  return (
    <AppBar position="fixed">
      <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
        <Stack direction="row" onClick={handleLogoClick} style={cursorStyle}>
          <img alt="" src={headerDocopaLogo} width="40" />
          <Typography variant="h5" noWrap marginTop="4px" marginLeft="4px">
            Docopa
          </Typography>
          {isManagePath ? (
            <Typography variant="h5" noWrap marginTop="4px" marginLeft="4px">
              管理者
            </Typography>
          ) : null}
        </Stack>
        {signOutBtn && (
          <Button color="inherit" onClick={handleLogout}>
            SIGN OUT
          </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default DocopaHeader;
