import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import preval from "preval.macro";
import dayjs from "dayjs";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import API_ADD from "../config.js";

function Version() {
  const buildTimestamp = preval`module.exports = new Date().getTime();`;
  const lastUpdateDayjs = dayjs.unix(buildTimestamp / 1000); // moment.unix()と同様の方法でUnixタイムスタンプを解析
  const formattedDate = lastUpdateDayjs.format("YYYY/MM/DD HH:mm:ss");

  const [data, setData] = useState([]);

  useEffect(() => {
    // 動画情報の取得APIコール
    const fetchData = async () => {
      try {
        const response = await axios.get(API_ADD.GET_SERVER_VERSION);
        setData(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []); // 空の依存リストを指定して、コンポーネントがマウントされたときにのみAPIコールを行う

  return (
    <>
      <Box>
        <Typography variant="h5">
          クライアント側リリースバージョン：{window._env_.RELEASE_VERSION}
        </Typography>
        <Typography variant="h5">Build Date：{formattedDate}</Typography>
        <br />
        <Typography variant="h5">
          サーバー側リリースバージョン：{data.version}
        </Typography>
        <Typography variant="h5">Build Date：{data.buildTime}</Typography>
      </Box>
    </>
  );
}

export default Version;
