import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// ビデオカードリストコンポーネント
import VideoCard from "./VideoCard.js";

/**
 * ドコモのトップ・検索結果
 * @parmas : data getSearchListリクエスト
 * @parmas : viewFlg 画面フラグ top : 0 / tag : 1 / 検索 : 2
 */
function VideoCardList({ data, viewFlg, lastFlg }) {
  // リアルタイムで画面サイズの変更を検知・値を取得
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    const onResize = () => {
      setWindowDimensions(getWindowDimensions());
    };
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);
  // ブラウザの幅により動画カード表示設定
  useEffect(() => {
    if (windowDimensions.width >= 1536) setCardCount(8);
    else if (windowDimensions.width >= 1200) setCardCount(6);
    else if (windowDimensions.width >= 900) setCardCount(4);
  }, [windowDimensions]);
  // 動画カードの表示数
  const [cardCount, setCardCount] = useState(6);

  const navigate = useNavigate();

  // もっと見るボタン処理
  const searchMore = (tagName) => {
    navigate("/tagvideolist", { state: { tagName } });
  };
  return (
    <>
      {/*タグ単位の繰り返し処理 */}
      <Grid sx={{ display: "flex", alignItems: "end" }}>
        <Typography
          variant="h4"
          color="inherit"
          noWrap
          sx={{ minWidth: "800px" }}
        >
          {data.tag}
          <span style={{ fontSize: 20 }}>
            {viewFlg === 2
              ? ` (検索結果 ${data.video_count}件)`
              : ` (${data.video_count}件)`}
          </span>
        </Typography>
      </Grid>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          {
            /* タグ内の動画単位の繰り返し処理 topの場合、5件まで表示表示 */
            data.video_list
              .slice(0, viewFlg !== 0 ? undefined : cardCount)
              .map((contents, j) => (
                <VideoCard key={j} contents={contents} />
              ))
          }
        </Grid>
        <Grid container spacing={1}>
          {
            /* top && ７件以上ある場合もっとみるを表示 */
            viewFlg === 0 && data.video_count > cardCount && (
              <Button
                onClick={() => searchMore(data.tag)}
                size="large"
                sx={{ mx: "auto" }}
              >
                もっと見る
              </Button>
            )
          }
          {viewFlg === 1 && (
            <Button href={"/top"} size="large" sx={{ mx: "auto" }}>
              Topへ戻る
            </Button>
          )}
        </Grid>
        {!lastFlg && <Divider style={{ margin: "30px 0px" }} />}
      </Box>
    </>
  );
}

export default VideoCardList;
