import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";

import { useNavigate } from "react-router-dom";
/**
 * ビデオ情報を表示するカード
 * @parmas contents : 一個video情報
 * @return : 一個のvideo情報カードUI
 */
function VideoCard({ contents }) {
  let navigate = useNavigate();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  return (
    <Grid lg={4} xl={3}>
      <Item elevation={0}>
        <Card //カードの書式設定
          sx={{
            maxWidth: 345,
            textAlign: "left",
          }}
          onClick={() => navigate(`/playmovie/${contents.id}`)}
        >
          <CardActionArea>
            <CardMedia //サムネイルの書式設定
              component="img"
              image={
                contents.setting_thumbnail_url
                  ? contents.setting_thumbnail_url
                  : contents.thumbnail_url
              }
              alt="thumbnail"
            />
            <CardContent //動画タイトルの書式設定
              sx={{
                height: 80,
                maxHeight: 80,
                maxWidth: 345,
                overflow: "hidden",
                p: 1,
              }}
            >
              <Typography
                gutterBottom
                variant="subtitle1"
                component="div"
                sx={{
                  height: 70,
                  maxHeight: 70,
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                  lineHeight: 1.5,
                  m: 0,
                }}
              >
                {contents.title}
              </Typography>
            </CardContent>
            <CardContent sx={{ p: 1 }}>
              <Typography variant="body2" color="text.secondary">
                <span>
                  更新日:
                  {contents.update_date.split("T", 1)}
                </span>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <span>再生数:{contents.views}</span>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Item>
    </Grid>
  );
}
export default VideoCard;
