import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import * as React from "react";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import DocopaDrawer from "./components/DocopaDrawer.js";
import Top from "./views/Top.js";
import Playmovie from "./views/Playmovie.js";
import TagVideoList from "./views/TagVideoList.js";
import Manage from "./views/Manage.js";
import Edit from "./views/Edit.js";
import Register from "./views/Register.js";
import Version from "./views/Version.js";
import NotFound from "./views/NotFound.js";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import axios from "axios";
import API_ADD from "./config.js";

import { Amplify } from "aws-amplify";
import { Authenticator, Heading } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Typography } from "@mui/material";
import { fetchUserGroups, formFields } from "./variables/ManageRelated.js";
import { I18n } from "aws-amplify";
import cognitoVocabularies from "./variables/CognitoVocabularies.js";
import { translations } from "@aws-amplify/ui-react";
// ドコパヘッダーコンポーネント
import DocopaHeader from "./components/DocopaHeader.js";

Amplify.configure({
  Auth: {
    region: "ap-northeast-1",
    userPoolId: window._env_.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: window._env_.REACT_APP_COGNITO_USERPOOL_WEBCLIENT_ID,
  },
});

// 日本語設定
I18n.putVocabularies(translations);
I18n.setLanguage("ja");
I18n.putVocabularies(cognitoVocabularies);

/** ログイン画面のヘッダー */
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

function App() {
  const location = useLocation();
  const isManagePath = location.pathname.startsWith("/manage");
  const theme = createTheme({
    components: {
      MuiCssBaseline: {
        styleOverrides: `
            ::-webkit-scrollbar {
            width: 18px;
            },
            ::-webkit-scrollbar-thumb {
                background-color: gray;
                border-radius: 12px;
                border-top: 4px solid transparent;
                border-bottom: 4px solid transparent;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
                background-clip: padding-box;
            }
            `,
      },
    },
  });

  // メンテナンス確認
  const [isMaintenace, setIsMaintenace] = useState(false);
  const isMaintenacePath = location.pathname === "/";
  const [maintenaceMeg, setMaintenaceMeg] = useState(null);
  const [loding, setLoding] = useState(true);
  // ログインユーザーの権限確認
  const [userGroups, setUserGroups] = useState([]);
  // ログインユーザー確認
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    // メンテナンス判断
    axios.get(API_ADD.GET_IMFORMATION).then((res) => {
      if (res.data.information.length !== 0) {
        setIsMaintenace(true);
        setMaintenaceMeg(res.data.information);
      }
      setLoding(false);
    });

    // ユーザーのグループ取得
    fetchUserGroups(setUserGroups, setUserInfo);
  }, []);

  /** メンテナンス画面内容 */
  function MaintenaceScreen() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: maintenaceMeg }} />
      </div>
    );
  }

  // ログイン関連画面設定
  const components = {
    Header() {
      return (
        <DocopaHeader
          isManagePath={isManagePath}
          cursor={false}
          handleLogoClick={null}
          signOutBtn={userInfo && true}
        />
      );
    },
    SetupTOTP: {
      Header() {
        return (
          <>
            <Heading level={4}>MFA設定</Heading>
            <Typography sx={{ fontSize: 14 }}>
              Google
              AuthenticatorやAuthy等でQRコードを読み取ってセットアップしてください
            </Typography>
          </>
        );
      },
    },
  };

  if (loding) return <div></div>; // メンテナンス画面読み込み中
  return (
    <div>
      <Authenticator
        // hideSignUp={true}
        formFields={formFields}
        components={components}
        className="centered"
      >
        {({ signOut, user }) => (
          <ThemeProvider theme={theme}>
            <Box sx={{ display: "flex" }}>
              <CssBaseline />
              {isManagePath || (isMaintenacePath && isMaintenace) ? null : (
                <DocopaDrawer />
              )}
              <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                <Routes>
                  <Route
                    path="/"
                    element={isMaintenace ? <MaintenaceScreen /> : <Top />}
                  />
                  <Route path="/top" element={<Top />} />
                  <Route path="/playmovie/:id" element={<Playmovie />} />
                  <Route path="/tagvideolist" element={<TagVideoList />} />
                  <Route
                    path="/manage"
                    element={userGroups ? <Manage /> : <NotFound />}
                  />
                  <Route path="/manage/register" element={<Register />} />
                  <Route path="/manage/edit" element={<Edit />} />
                  <Route path="/version" element={<Version />} />
                  <Route path="/*" element={<NotFound />} />
                </Routes>
              </Box>
            </Box>
          </ThemeProvider>
        )}
      </Authenticator>
    </div>
  );
}

export default App;
